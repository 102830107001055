#ewisemaps-nav-vertical {
    background-color: #2C364C;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 70px;
    z-index: 1000;
}

#ewisemaps-nav-vertical .logo {
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1em 0;
}

#ewisemaps-nav-vertical .logo img{
    width: 100%;
    height: 50px;
}

#ewisemaps-nav-vertical .navigation{
    max-width: 100%;
}

#ewisemaps-nav-vertical .navigation ul{
    list-style: none;
    color: #fbfbfb;
    height: 80vh;
    overflow-y: auto;
    overflow-x: hidden;
    margin: 0;
    padding: 0;
}

#ewisemaps-nav-vertical .navigation ul::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

#ewisemaps-nav-vertical .navigation ul::-webkit-scrollbar-track {
    background: #f1f1f1; 
}

#ewisemaps-nav-vertical .navigation ul::-webkit-scrollbar-thumb {
    background: #888; 
    border-radius: 3px;
}

#ewisemaps-nav-vertical .navigation ul::-webkit-scrollbar-thumb:hover {
    background: #444; 
}

#ewisemaps-nav-vertical .navigation ul li {
    text-align: left;
    height: 10vh;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding-left: 30%;
}

#ewisemaps-nav-vertical .navigation a {
    font-size: 1.5em;
    color: #fbfbfb !important;
}

#ewisemaps-nav-vertical .navigation a svg{
    font-size: 1.5em;
    color: #fbfbfb !important;
}

#ewisemaps-nav-vertical .navigation a:hover {
    color: #fbfbfb !important;
}


/* Media Screen */
@media only screen and (max-width: 1920px) {
    #ewisemaps-nav-vertical {
        width: 70px;
    }
    #ewisemaps-nav-vertical .logo img{
        height: 50px;
        width: 50px;
    }
    #ewisemaps-nav-vertical .navigation ul li {
        padding-left: 30%;
    }
}

@media only screen and (max-width: 1600px) {
    #ewisemaps-nav-vertical {
        width: 60px;
    }
    #ewisemaps-nav-vertical .logo img{
        height: 40px;
        width: 40px;
    }
    #ewisemaps-nav-vertical .navigation ul li {
        padding-left: 25%;
    }
}

@media only screen and (max-width: 1366px) {
    #ewisemaps-nav-vertical {
        width: 55px;
    }
    #ewisemaps-nav-vertical .logo img{
        height: 35px;
        width: 35px;
    }
}

@media only screen and (max-width: 1280px) {
    #ewisemaps-nav-vertical {
        width: 50px;
    }
    #ewisemaps-nav-vertical .logo img{
        height: 30px;
        width: 30px;
    }
}

@media only screen and (max-width: 600px) {
    #ewisemaps-nav-vertical {
        display: none;
    }
}