.chart-bar-horizontal {
    width: 100%;
    height: 400px;
}

.chart-bar-horizontal .recharts-text {
    font-size: 15px;
}

.chart-bar-horizontal .recharts-label {
    font-size: 12px;
}

.chart-bar-horizontal .yAxis text {
    font-size: 12px;
}

.chart-bar-horizontal .custom-tooltip {
    background-color: #fff;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    max-width: 350px;
    font-size: 1em;
    padding: 0.5em;
}

.chart-bar-horizontal .custom-tooltip p {
    text-align: left;
}


/* Media Screen */
@media only screen and (max-width: 1920px) {
    .chart-bar-horizontal {
        height: 400px;
    }
    .chart-bar-horizontal .recharts-text {
        font-size: 14px;
    }
    .chart-bar-horizontal .recharts-label {
        font-size: 12px;
    }
    .chart-bar-horizontal .yAxis text {
        font-size: 12px;
    }
    .chart-bar-horizontal .custom-tooltip {
        max-width: 350px;
        font-size: 1em;
    }
}

@media only screen and (max-width: 1600px) {
    .chart-bar-horizontal {
        height: 380px;
    }
    .chart-bar-horizontal .recharts-text {
        font-size: 13px;
    }
    .chart-bar-horizontal .recharts-label {
        font-size: 11px;
    }
    .chart-bar-horizontal .custom-tooltip {
        max-width: 350px;
        font-size: 1em;
    }
}

@media only screen and (max-width: 1366px) {
    .chart-bar-horizontal {
        height: 350px;
    }
    .chart-bar-horizontal .recharts-text {
        font-size: 12px;
    }
    .chart-bar-horizontal .recharts-label {
        font-size: 10px;
    }
    .chart-bar-horizontal .custom-tooltip {
        max-width: 350px;
        font-size: 1em;
    }
}

@media only screen and (max-width: 1280px) {
    .chart-bar-horizontal {
        height: 320px;
    }
    .chart-bar-horizontal .recharts-text {
        font-size: 11px;
    }
    .chart-bar-horizontal .recharts-label {
        font-size: 9px;
    }
    .chart-bar-horizontal .custom-tooltip {
        max-width: 350px;
        font-size: 1em;
    }
}

@media only screen and (max-width: 1024px) {
    .chart-bar-horizontal .recharts-text {
        font-size: 10px;
    }
    .chart-bar-horizontal .recharts-label {
        font-size: 9px;
    }
    .chart-bar-horizontal .custom-tooltip {
        max-width: 350px;
        font-size: 1em;
    }
}

@media only screen and (max-width: 800px) {
    .chart-bar-horizontal .recharts-text {
        font-size: 10px;
    }
    .chart-bar-horizontal .recharts-label {
        display: none;
    }
    .chart-bar-horizontal .custom-tooltip {
        max-width: 350px;
        font-size: 1em;
    }
}

@media only screen and (max-width: 600px) {
    .chart-bar-horizontal {
        height: 280px;
    }
    .chart-bar-horizontal .custom-tooltip {
        max-width: 300px;
        font-size: 0.8em;
    }
}
