#ewisemaps-nav-horizontal {
    position: fixed;
    top: 0;
    left: 0;
    border-bottom: 1px #ccc solid;
    width: 100%;
    height: 70px;
    background-color: #fff;
    z-index: 1000;
}

#ewisemaps-nav-horizontal a {
    color: #262626
}

#ewisemaps-nav-horizontal .menu-navbar {
    position: fixed;
    font-size: 1.3em;
    left: 15px;
    top: 10px;
}

#ewisemaps-nav-horizontal .search {
    margin-left: 100px;
    width: auto;
    height: 70px;
    display: flex;
    align-items: center;
    padding: 0;
}

.react-mapbox-ac-input {
    margin-bottom: auto;
}

.react-mapbox-ac-menu {
    z-index: 1100;
    margin-top: auto;
}

#ewisemaps-nav-horizontal .search .react-mapbox-ac-input{
    font-size: 1em;
    width: 100% !important;
    border: none;
    border-bottom: #ccc 1px solid;
}

#ewisemaps-nav-horizontal .search .reset-geocoding {
    background-color: transparent;
    border: none;
    color: #555;
    font-size: 1.1em;
}

#ewisemaps-nav-horizontal .search .react-mapbox-ac-input:active{
    border-bottom: #2C364C 1px solid;
}

#ewisemaps-nav-horizontal .search .react-mapbox-ac-input:focus{
    border-bottom: #2C364C 1px solid;
}

#ewisemaps-nav-horizontal .activities {
    position: absolute;
    right: 0;
    top:0;
    width: 150px;
    height: 70px;
    padding: 0;
}

#ewisemaps-nav-horizontal .activities-items {
    list-style: none;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 70px;
    padding: 0 0.5em;
}

#ewisemaps-nav-horizontal .activities-item{
    width: 31%;
    margin: 1em;
    text-align: center;
    font-size: 1.3em;
}

#ewisemaps-nav-horizontal .ewisemaps-item-actions {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

#ewisemaps-nav-horizontal .ewisemaps-item-actions i {
    width: 5%;
    text-align: left;
    color: #363636;
}

#ewisemaps-nav-horizontal .ewisemaps-item-actions span {
    width: 95%;
    text-align: right;
    color: #363636;
}

#ewisemaps-nav-horizontal .ewisemaps-item-actions span p{
    width: 200px;
    padding: 0;
    margin: 0 1em;
}

#ewisemaps-nav-mobile {
    background-color: rgba(0,0,0,0.4);
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1000;
}

#ewisemaps-nav-mobile button {
    width: 30%;
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    background-color: transparent;
    border: none;
}

#ewisemaps-nav-mobile ul {
    overflow-y: auto;
    width: 70%;
    height: 100vh;
    padding: 50px 1em;
    list-style: none;
    background-color: #fff;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}

#ewisemaps-nav-mobile ul li {
    color: #262626;
    font-weight: 300;
    padding: 2em 0;
    font-size: 1em;
}

#ewisemaps-nav-mobile ul li i {
    margin-right: 1em;
}

.dropdown-menu{
    min-width: 15rem;
}

.menu-user-icon{
    display: inline-block;
    text-align: center;
}

.menu-user-icon i{
    margin: 0.1em;
}

.menu-user-icon span{
    text-align: center;
}

#ewisemaps-nav-horizontal .menu-user-item{
    width: 100%;
}


/* Media Screen */
@media only screen and (max-width: 1920px) {
    #ewisemaps-nav-horizontal {
        height: 70px;
    }
    #ewisemaps-nav-horizontal .search {
        width: 210px;
        height: 70px;
    }
    #ewisemaps-nav-horizontal .activities {
        width: 250px;
        height: 70px;
        padding: 0;
    }
    #ewisemaps-nav-horizontal .activities-items {
        height: 70px;
    }
    #ewisemaps-nav-horizontal .mobile {
        display: none;
    }
    #ewisemaps-nav-mobile {
        display: none;
    }
}

@media only screen and (max-width: 1600px) {
    #ewisemaps-nav-horizontal {
        height: 60px;
    }
    #ewisemaps-nav-horizontal .search {
        width: 190px;
        height: 60px;
    }
    #ewisemaps-nav-horizontal .search .react-mapbox-ac-input{
        font-size: 0.9em;
    }
    #ewisemaps-nav-horizontal .activities {
        width: 250px;
        height: 60px;
        padding: 0;
    }
    #ewisemaps-nav-horizontal .activities-items {
        height: 60px;
    }
}

@media only screen and (max-width: 1366px) {
    #ewisemaps-nav-horizontal {
        height: 55px;
    }
    #ewisemaps-nav-horizontal .search {
        width: 170px;
        height: 55px;
    }
    #ewisemaps-nav-horizontal .search .react-mapbox-ac-input{
        font-size: 0.8em;
    }
    #ewisemaps-nav-horizontal .activities {
        width: 250px;
        height: 55px;
        padding: 0;
    }
    #ewisemaps-nav-horizontal .activities-items {
        height: 55px;
    }
}

@media only screen and (max-width: 1280px) {
    #ewisemaps-nav-horizontal {
        height: 50px;
    }
    #ewisemaps-nav-horizontal .search {
        width: 170px;
        height: 50px;
    }
    #ewisemaps-nav-horizontal .search .react-mapbox-ac-input{
        font-size: 0.8em;
    }
    #ewisemaps-nav-horizontal .activities {
        width: 250px;
        height: 50px;
        padding: 0;
    }
    #ewisemaps-nav-horizontal .activities-items {
        height: 50px;
    }
    
}

@media only screen and (max-width: 1024px) {
    #ewisemaps-nav-horizontal .search {
        margin-left: 60px;
    }
    #ewisemaps-nav-horizontal .mobile {
        display: block;
    }
    #ewisemaps-nav-horizontal .menu-navbar {
        display: none;
    }
}

@media only screen and (max-width: 800px) {
    #ewisemaps-nav-horizontal .menu-navbar {
        display: block;
    }
}

@media only screen and (max-width: 600px) {
    #ewisemaps-nav-horizontal .search {
        margin-left: 50px;
    }
    #ewisemaps-nav-horizontal .search .react-mapbox-ac-input{
        width: auto;
    }
    #ewisemaps-nav-horizontal .activities {
        width: 170px;
    }
    #ewisemaps-nav-mobile {
        display: block;
    }
}

