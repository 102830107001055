.modal-historical-analisis .datatable-actions {
    width: 100%;
    text-align: right;
}

.modal-historical-analisis .datatable-actions button{
    margin: 0 1em;
    background-color: transparent;
    padding: 0.1em 1em;
    border: #2C364C solid 1px;
    color: #2C364C;
}

.modal-historical-analisis .datatable-actions button:first-child{
    border: #FD830C solid 1px;
    color: #FD830C;
}

.modal-historical-analisis .datatable-historical-analisis .rdt_TableHeadRow .rdt_TableCol {
    font-weight: 400;
    font-size: 1em;
}

.modal-historical-analisis .datatable-historical-analisis .rdt_TableRow .rdt_TableCell button {
    border: none;
    background-color: transparent;
    font-size: 1.5em;
}

.modal-historical-analisis .datatable-historical-analisis .rdt_TableRow .rdt_TableCell button .ion-eye {
    color: #FD830C;
}

.modal-historical-analisis .datatable-historical-analisis .rdt_TableRow .rdt_TableCell button .ion-trash-a {
    color: #2C364C;
}

