#app-analytic-section {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 70px;
    top: 70px;
    background-color: none;
}

#app-analytic-section .title{
    width: 98%;
    margin: 0 1%;
    padding: 0.5em 0;
    font-weight: 300;
    font-size: 2em;
}

#app-analytic-section .app-cards {
    overflow-y: auto;
    display: flex;
    align-items: initial;
    justify-content: left;
    flex-wrap: wrap;
    flex-direction: row;
}

#app-analytic-section .app-card-2 {
    width: 18%;
    margin: 1em 1%;
    height: auto;
    background-color: #fff;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    padding: 2em;
    text-align: center;
}

#app-analytic-section .app-card-2-5 {
    width: 23%;
    margin: 1em 1%;
    height: auto;
    background-color: #fff;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    padding: 2em;
    text-align: center;
}

#app-analytic-section .app-card-5 {
    width: 48%;
    margin: 1em 1%;
    height: auto;
    background-color: #fff;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    padding: 2em;
    text-align: center;
}

#app-analytic-section .app-card-8 {
    width: 78%;
    margin: 1em 1%;
    height: auto;
    background-color: #fff;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    padding: 2em;
    text-align: center;
}

#app-analytic-section .app-card-10 {
    width: 98%;
    margin: 1em 1%;
    height: auto;
    background-color: #fff;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    padding: 2em;
    text-align: center;
}

#app-analytic-section .app-card-max{
    width: 73%;
    margin: 1em 1%;
    height: auto;
    background-color: #fff;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    padding: 2em;
    text-align: center;
}



/* Media Screen */
@media only screen and (max-width: 1920px) {
    #app-analytic-section{
        left: 70px;
        top: 70px;
    }
    #app-analytic-section .app-card-2 {
        width: 18%;
        margin: 1em 1%;
    }
    #app-analytic-section .app-card-2-5 {
        width: 23%;
        margin: 1em 1%;
    }
    #app-analytic-section .app-card-5 {
        width: 48%;
        margin: 1em 1%;
    }
    #app-analytic-section .app-card-8 {
        width: 78%;
        margin: 1em 1%;
    }
    #app-analytic-section .app-card-10 {
        width: 98%;
        margin: 1em 1%;
    }
}

@media only screen and (max-width: 1600px) {
    #app-analytic-section{
        left: 60px;
        top: 60px;
    }
}

@media only screen and (max-width: 1366px) {
    #app-analytic-section{
        left: 55px;
        top: 55px;
    }
    #app-analytic-section .app-card-2 {
        width: 23%;
        margin: 1em 1%;
    }
    #app-analytic-section .app-card-8 {
        width: 73%;
        margin: 1em 1%;
    }
}

@media only screen and (max-width: 1280px) {
    #app-analytic-section{
        left: 50px;
        top: 50px;
    }
    
}

@media only screen and (max-width: 1024px) {
    #app-analytic-section .map{
        height: 82vh;
    }
    #app-analytic-section .app-card-2 {
        width: 98%;
        margin: 1em 1%;
    }
    #app-analytic-section .app-card-2-5 {
        width: 48%;
        margin: 1em 1%;
    }
    #app-analytic-section .app-card-5 {
        width: 48%;
        margin: 1em 1%;
    }
    #app-analytic-section .app-card-8 {
        width: 98%;
        margin: 1em 1%;
    }
    #app-analytic-section .app-card-10 {
        width: 98%;
        margin: 1em 1%;
    }
}

@media only screen and (max-width: 800px) {
    #app-analytic-section .map{
        height: 85vh;
    }
    #app-analytic-section .app-card-2 {
        width: 98%;
        margin: 1em 1%;
    }
    #app-analytic-section .app-card-5 {
        width: 98%;
        margin: 1em 1%;
    }
    #app-analytic-section .app-card-8 {
        width: 98%;
        margin: 1em 1%;
    }
}

@media only screen and (max-width: 700px) {
    #app-analytic-section{
        left: 0;
        top: 50px;
    }
    #app-analytic-section .title{
        font-size: 1.3em;
    }
    #app-analytic-section .app-card-2-5 {
        width: 98%;
        margin: 1em 1%;
    }
}
