.filter-state ul {
    list-style: none;
    width: 100%;
    padding: 1em 0;
    display: flex;
    align-items: flex-start;
    justify-content: left;
    flex-direction: row;
    flex-wrap: wrap;
}

.filter-state ul li {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0;
    margin: 0.5em 2%;
    width: 16%;
}



/* Media Screen */
@media only screen and (max-width: 1920px) {
    .filter-state ul li {
        margin: 0.5em 1%;
        width: 31%;
    }
}

@media only screen and (max-width: 1000px) {
    .filter-state ul li {
        margin: 0.5em 1%;
        width: 98%;
    }
    .filter-state ul {
        width: 100%;
        height: 280px;
        overflow-y: auto;
    }
}