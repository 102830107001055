.clients-adt-cards {
    width: 100%;
    padding: 0;
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    background-color: #f9f9f9;
}

.clients-adt-card {
    width: 31%;
    margin: 1em 1%;
    background-color: #fff;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}

.clients-adt-card h5 {
    font-size: 1.1em;
    width: 100%;
    padding: 0.5em;
    text-align: center;
}

.clients-adt-card .logo {
    width: 38%;
    margin: 1em 1%;
}

.clients-adt-card .logo img {
    max-width: 100%;
    max-height: 100px;
}

.clients-adt-card .side {
    width: 58%;
    margin: 1em 1%;
    font-size: 0.9em;
}

.clients-adt-card .side p {
    margin: 0.1em;
}

.clients-adt-card .address {
    width: 98%;
    margin: 0.5em 1%;
    text-align: center;
    font-size: 0.9em;
}


/* Media Screen */
@media only screen and (max-width: 1920px) {
    .clients-adt-card {
        width: 31%;
        margin: 1em 1%;
    }
}

@media only screen and (max-width: 1920px) {
    .clients-adt-card .logo img {
        max-height: 70px;
    }
}

@media only screen and (max-width: 1366px) {
    .clients-adt-card {
        width: 48%;
        margin: 1em 1%;
    }
    .clients-adt-card h5 {
        font-size: 1em;
    }
    .clients-adt-card .side {
        font-size: 0.8em;
    }
    .clients-adt-card .address {
        font-size: 0.8em;
    }
    
}

@media only screen and (max-width: 1280px) {
    .clients-adt-card .address {
        font-size: 0.7em;
    }
}

@media only screen and (max-width: 700px) {
    .clients-adt-cards {
        background-color: transparent;
    }
    .clients-adt-card {
        width: 98%;
        margin: 1em 1%;
    }
    .clients-adt-card .logo {
        width: 98%;
        margin: 1em 1%;
        text-align: center;
    }
    .clients-adt-card .logo img {
        max-height: 50px;
    }
    .clients-adt-card .side {
        width: 98%;
        margin: 1em 1%;
        padding: 1em;
    }
}
