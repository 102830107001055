.municipality-mexico-cards {
    width: 100%;
    padding: 0;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
}

.municipality-mexico-card {
    width: 96%;
    margin: 1em 2%;
    height: 80px;
    border: #ccc 1px solid;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row;
    border-left: #68CA73 6px solid;
}


.municipality-mexico-card  h5 {
    font-size: 1.3em;
    font-weight: 300;
}

/* Media Screen */
@media only screen and (max-width: 1920px) {
    .municipality-mexico-card {
        margin: 1em 2%;
    }
    .municipality-mexico-card  h5 {
        font-size: 1.2em;
    }
}

@media only screen and (max-width: 1600px) {
    .municipality-mexico-card {
        margin: 0.5em 2%;
    }
    .municipality-mexico-card  h5 {
        font-size: 1.1em;
    }
}

@media only screen and (max-width: 1366px) {
    .municipality-mexico-card  h5 {
        font-size: 0.9em;
    }
}

@media only screen and (max-width: 1280px) {
    .municipality-mexico-card  h5 {
        font-size: 0.8em;
    }
}

@media only screen and (max-width: 1024px) {
    .municipality-mexico-card  h5 {
        font-size: 0.7em;
    }
}
