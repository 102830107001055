#app-map-section{
    position: absolute;
    bottom: 0;
    right: 0;
    left: 70px;
    top: 70px;
    background-color: none;
}

#app-map-section .app-cards{
    overflow-y: auto;
    display: flex;
    flex-direction: wrap;
}

#app-map-section .app-card-min{
    width: 23%;
    margin: 2em 1%;
    height: auto;
    background-color: #fff;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    padding: 1em;
    text-align: center;
}

#app-map-section .app-card-max{
    width: 73%;
    margin: 2em 1%;
    height: auto;
    background-color: #fff;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    padding: 1em;
    text-align: center;
}

#app-map-section #ewisemaps-map{
    width: 100%;
    height: 80vh;
    display: block;
    position: relative;
}


/* Media Screen */
@media only screen and (max-width: 1920px) {
    #app-map-section{
        left: 70px;
        top: 70px;
    }
    #app-map-section .app-card-min{
        width: 23%;
        margin: 2em 1%;
        padding: 1em;
    }
    #app-map-section .app-card-max{
        width: 73%;
        margin: 2em 1%;
        padding: 1em;
    }
    #app-map-section #ewisemaps-map{
        height: 82vh;
    }
}

@media only screen and (max-width: 1600px) {
    #app-map-section{
        left: 60px;
        top: 60px;
    }
    #app-map-section .app-card-min{
        width: 23%;
        margin: 1em 1%;
        padding: 1em;
    }
    #app-map-section .app-card-max{
        width: 73%;
        margin: 1em 1%;
        padding: 1em;
    }
}

@media only screen and (max-width: 1366px) {
    #app-map-section{
        left: 55px;
        top: 55px;
    }
    #app-map-section #ewisemaps-map{
        height: 80vh;
    }
}

@media only screen and (max-width: 1280px) {
    #app-map-section{
        left: 50px;
        top: 50px;
    }
    #app-map-section #ewisemaps-map{
        height: 78vh;
    }
}

@media only screen and (max-width: 1024px) {
    #app-map-section #ewisemaps-map{
        height: 81vh;
    }
    #app-map-section .app-card-min{
        display: none;
    }
    #app-map-section .app-card-max{
        width: 98%;
        margin: 2em 1%;
    }
}

@media only screen and (max-width: 800px) {
    #app-map-section #ewisemaps-map{
        height: 85vh;
    }
    #app-map-section .app-card-max{
        margin: 1%;
        padding: 0;
    }
}

@media only screen and (max-width: 600px) {
    #app-map-section{
        left: 0;
        top: 30px;
    }
    #app-map-section #ewisemaps-map{
        height: 94vh;
    }
}
