.features-modal .modal-content .col-xl-2 .nav-link.active{
    background-color: transparent;
    border-radius: 0;
    color: #555;
    border-left: #FD830C 3px solid;
}

.features-modal .modal-content .col-xl-2 .nav-link{
    background-color: transparent;
    color: #555;
    border-radius: 0;
}


/* Media Screen */
@media only screen and (max-width: 1920px) {
    .features-modal .modal-dialog {
        max-width: 80%;
    }
}

/* Media Screen */
@media only screen and (max-width: 1000px) {
    .features-modal .modal-dialog {
        max-width: 95%;
    }
}
