.search-info {
    width: 100%;
    padding: 0.5em;
}

.search-info h6 {
    text-align: center;
}

.search-info .search-btn {
    border: none;
    background-color: #FD830C;
    color: #fff;
    position: absolute;
    right: 20%;
    padding: 0.35em 0.5em;
}

.search-info input[type="text"] {
    border: none;
    border-bottom: 2px #ccc solid;
    width: 50%;
    margin: 0.5em 25%;
}

.search-info input[type="text"]:hover {
    border-bottom: 2px #FD830C solid;
}

.search-info input[type="text"]:active {
    border-bottom: 2px #FD830C solid;
}

.search-info input[type="text"]:focus {
    border-bottom: 2px #FD830C solid;
}

.search-info .results {
    width: 100%;
    padding: 0.5em;
    display: flex;
    align-items: flex-start;
    justify-content: left;
    flex-wrap: wrap;
    flex-direction: row;
}

.search-info .results .result {
    width: 31%;
    margin: 1em 1%;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    padding: 0.5em;
    text-align: center;
}

.search-info .results .result p {
    font-weight: bold;
}

.search-info .results .result button {
    border: none;
    background-color: #FD830C;
    color: #fff;
}


@media only screen and (max-width: 600px) {
    .search-info .results .result {
        width: 48%;
    }
}