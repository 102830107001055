.filter-custom-string input[type="search"]{
    width: 40%;
    padding: 0.5em;
    font-size: 0.9em;
    border: none;
    border-bottom: #ccc 2px solid;
}

.filter-custom-string button {
    position: absolute;
    top: 1em;
    right: 2em;
    border: none;
    background-color: #FD830C;
    color: #fff;
    padding: 0.3em;
}

.filter-custom-string ul {
    list-style: none;
    width: 100%;
    padding: 1em 0;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    height: 400px;
    overflow-y: auto;
}

.filter-custom-string ul li {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0.5em 2%;
    width: 16%;

}


/* Media Screen */
@media only screen and (max-width: 1920px) {
    .filter-custom-string ul li {
        margin: 1em 8%;
        width: 17%;
    }
    .filter-custom-string ul li span {
        width: 80%;
    }
}

@media only screen and (max-width: 1600px) {
    .filter-custom-string ul {
        height: 270px;
    }
}

@media only screen and (max-width: 1366px) {
    .filter-custom-string ul {
        height: 250px;
    }
}

@media only screen and (max-width: 1280px) {
    .filter-custom-string ul li {
        margin: 0.5em 1%;
        width: 31%;
    }
}


@media only screen and (max-width: 1024px) {
    .filter-custom-string ul {
        height: 310px;
    }
    .filter-custom-string button {
        top: 8em;
    }
}

@media only screen and (max-width: 700px) {
    .filter-custom-string ul {
        height: 280px;
    }
    .filter-custom-string ul li {
        margin: 0.5em 1%;
        width: 98%;
    }
    .filter-custom-string input[type="search"]{
        width: 60%;
    }
    .filter-custom-string button {
        top: 9em;
        right: 1em;
        font-size: 0.9em;
        width: 130px;
        padding: 0.3em 0.1em;
    }
}



