.list-overlayers {
    width: 100%;
    padding: 2em 1em;
    height: 70vh;
    overflow-y: auto;
}

.list-overlayers .card {
    border-radius: 0;
    border:0;
}

.list-overlayers .card-header {
    background-color: #fff;
    padding: 0;
}

.list-overlayers .card-header button {
    width: 100%;
    text-align: left;
    padding: 1em;
    color: #262626;
}

.list-overlayers .card-header button:hover {
    text-decoration:none;
    outline: 0;
    color: #FD830C;
}

.list-overlayers .card-header button:focus {
    text-decoration:none;
    outline: 0;
    color: #FD830C;
}

.list-overlayers .card-header button:active {
    text-decoration:none;
    border: none;
    outline: 0;
    color: #FD830C;
}

.list-overlayers .card-header button i{
    float:right;
}

.list-overlayers .list-layers {
    list-style: none;
    width: 100%;
    padding: 1em 0;
}

.list-overlayers .list-layers li {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
}

.list-overlayers .list-layers li span {
    padding: 0.5em 0;
    width: 80%;
    text-align: left;
}


.list-overlayers .list-layers li .switch_box{
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	width: 20%;
	height: 50px;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	-webkit-box-flex: 1;
	    -ms-flex: 1;
	        flex: 1;
}


.list-overlayers .list-layers li .box_1{
	background: #fff;
}

.list-overlayers .list-layers li input[type="checkbox"].switch_1{
	font-size: 10px;
	-webkit-appearance: none;
	   -moz-appearance: none;
	        appearance: none;
	width: 2.5em;
	height: 1.5em;
	background: #ddd;
	border-radius: 20px;
	position: relative;
	cursor: pointer;
	outline: none;
	-webkit-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out;
}

.list-overlayers .list-layers li input[type="checkbox"].switch_1:checked{
    background: #FD830C;
}

.list-overlayers .list-layers li input[type="checkbox"].switch_1:after{
    position: absolute;
    content: "";
    width: 1.5em;
    height: 1.5em;
    border-radius: 50%;
    background: #fff;
    -webkit-box-shadow: 0 0 .25em rgba(0,0,0,.3);
            box-shadow: 0 0 .25em rgba(0,0,0,.3);
    -webkit-transform: scale(.7);
            transform: scale(.7);
    left: 0;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}

.list-overlayers .list-layers li input[type="checkbox"].switch_1:checked:after{
    left: calc(100% - 1.5em);
}


/* Media Screen */
@media only screen and (max-width: 1920px) {
    .list-overlayers {
        padding: 2em 1em;
        height: 72vh;
    }
    .list-overlayers li {
        width: 95%;
        margin: 0.5em 2.5%;
    }
    .list-overlayers .card-header button {
        font-size: 1em;
    }
    .list-overlayers .list-layers li span {
        font-size: 0.97em;
    }
}

@media only screen and (max-width: 1600px) {
    .list-overlayers {
        padding: 2em 1em;
        height: 70vh;
    }
    .list-overlayers li {
        width: 96%;
        margin: 0.5em 2%;
    }
    .list-overlayers .card-header button {
        font-size: 1em;
    }
    .list-overlayers .list-layers li span {
        font-size: 0.95em;
    }
}

@media only screen and (max-width: 1366px) {
    .list-overlayers {
        padding: 2em 1em;
        height: 67vh;
    }
    .list-overlayers .card-header button {
        font-size: 0.8em;
    }
    .list-overlayers .list-layers li span {
        font-size: 0.8em;
        width: 85%;
    }
    .list-overlayers .list-layers li .switch_box{
        width: 15%;
    }

}

@media only screen and (max-width: 1280px) {
    .list-overlayers {
        padding: 2em 1em;
        height: 66vh;
    }
    .list-overlayers .card-header button {
        font-size: 0.7em;
    }
    .list-overlayers .list-layers li span {
        font-size: 0.7em;
        width: 80%;
    }
    .list-overlayers .list-layers li .switch_box{
        width: 20%;
    }
    .list-overlayers .card-body {
        padding: 0;
    }
}

@media only screen and (max-width: 1024px) {
    .list-overlayers {
        padding: 2em 1em;
        height: 69vh;
    }
    .list-overlayers .card-header button {
        font-size: 0.8em;
    }
    .list-overlayers .list-layers li span {
        font-size: 0.8em;
        width: 85%;
    }
    .list-overlayers .list-layers li .switch_box{
        width: 15%;
    }
}
