#app-features-desktop-section {
    width: 100%;
    background-color: none;
    min-height: 500px;
    max-height: 500px;
    overflow-y: auto;
    text-align: center;
}

/* Media Screen */
@media only screen and (max-width: 1920px) {
    #app-features-desktop-section {
        min-height: 500px;
        max-height: 500px;
    }
}

@media only screen and (max-width: 1600px) {
    #app-features-desktop-section {
        min-height: 400px;
        max-height: 400px;
    }
}

@media only screen and (max-width: 1366px) {
    #app-features-desktop-section {
        min-height: 350px;
        max-height: 350px;
    }
    #app-features-desktop-section ul li span {
        font-size: 0.95em;
    }
}

@media only screen and (max-width: 1024px) {
    #features-desktop-tabs {
        display: none;
    }
}





