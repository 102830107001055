.toast.show {
    z-index: 2000;
}

.toast-message-filter { 
    position: fixed;
    top: 1em;
    right: 1em;
}

.toast-message-filter .toast-header button {
    color: #fff;
}

.message-1 .toast-header {
    background-color: #03B426;
    color: #fff;
}

.message-2 .toast-header {
    background-color: #EFA738;
    color: #fff;
}

.message-3 .toast-header {
    background-color: #B1060F;
    color: #fff;
}
