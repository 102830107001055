#app-profile-section{
    position: absolute;
    bottom: 0;
    right: 0;
    left: 70px;
    top: 70px;
}

#app-profile-section .app-cards {
    overflow-y: auto;
    display: flex;
    height: 100%;
}

#app-profile-section .title{
    width: 98%;
    margin: 0 1%;
    padding: 0.5em 0;
    font-weight: 300;
    font-size: 2em;
}

#app-profile-section .app-card-min{
    width: 23%;
    margin: 2em 1%;
    background-color: #fff;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    padding: 1em;
    text-align: center;
}

#app-profile-section .app-card-max{
    width: 73%;
    margin: 2em 1%;
    background-color: #fff;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    padding: 1em;
    text-align: left;
}

#app-profile-section .inputText{
    font-size: 1em;
    width: 60% ;
    border: none;
    border-bottom: #ccc 1px;
    border-bottom: #ccc 1px solid;
    margin: 1em;
}

#app-profile-section .button{
    width: 20%;
    margin: 1em;
    background-color: #FD830C;
    border: none;
    color: #fff;
    padding: 0.2em;
}

#app-profile-section .error{
    color: red;
    margin-left: 1em;
}

.toast.show {
    z-index: 2000;
}

.toast-message-filter {
    position: fixed;
    top: 1em;
    right: 1em;
}

.toast-message-filter .toast-header button {
    color: #fff;
}

.message-1 .toast-header {
    background-color: #03B426;
    color: #fff;
}

.message-2 .toast-header {
    background-color: #EFA738;
    color: #fff;
}


@media only screen and (max-width: 850px) {
    #app-profile-section .app-card-max {
        margin: 1%;
    }

    #app-profile-section .app-card-min{
        margin: 1%;
    }

    #app-profile-section .button{
        width: 60%;
    }

    #app-profile-section .inputText{
        width: 90% ;
        margin: 1%;
    }

    #app-profile-section .button{
        width: 90% ;
       margin: 1%;
    }
}
@media only screen and (max-width: 600px) {
    #app-profile-section{
        left: 10%;
        top: 10%;
    }

    #app-profile-section .app-cards {
        overflow-y: auto;
        display: block;
        height: auto;
    }

    #app-profile-section .app-card-min{
        display: none;
    }

    #app-profile-section .inputText{
        width: 90% ;
        margin: 1%;
    }

    #app-profile-section .button{
        width: 90%;
    }

    #app-profile-section .error{
        margin: 1%;
    }

    #app-profile-section .app-card-max {
        margin: 1%;
        width: 90%;
    }
}


