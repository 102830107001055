.list-features {
    width: 100%;
    overflow-y: auto;
}

.list-features .card {
    border-radius: 0;
    border:0;
}

.list-features .card-header {
    background-color: #2C364C;
    color: #fff;
    padding: 0;
}

.list-features .card-header button {
    width: 100%;
    text-align: left;
    padding: 1em;
    color: #fff;
}

.list-features .card-header button:hover {
    text-decoration:none;
    outline: 0;
}

.list-features .card-header button:focus {
    text-decoration:none;
    outline: 0;
}

.list-features .card-header button:active {
    text-decoration:none;
    border: none;
    outline: 0;
}

.list-features .card-header button i{
    float:right;
}

.list-features .list-layers {
    list-style: none;
    width: 100%;
    padding: 1em 0;
}

.list-features .list-layers li {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
}

.list-features .list-layers li span {
    padding: 0.5em 0;
    width: 80%;
    text-align: left;
}


/* Media Screen */
@media only screen and (max-width: 1920px) {
    .list-features {
        display: none;
    }
}

@media only screen and (max-width: 1024px) {
    .list-features {
        display: block;
        padding: 0;
        height: 70vh;
    }
    .list-features .card-body {
        flex: 1 1 auto;
        min-height: 1px;
        background-color: #f9f9f9;
    }
}