.mockup-devices {
    position: fixed;
    width: 40%;
    height: auto;
    left: 15%;
    top: 20%;
    z-index: 1000;
    padding:1em;
}

/* Media Screen */
@media only screen and (max-width: 1920px) {
    .mockup-devices {
        width: 40%;
        left: 15%;
        top: 20%;
    }
}

@media only screen and (max-width: 1600px) {
    .mockup-devices {
        width: 40%;
        left: 10%;
        top: 20%;
    }
}

@media only screen and (max-width: 1024px) {
    .mockup-devices {
        width: 40%;
        left: 5%;
        top: 20%;
    }
}

@media only screen and (max-width: 800px) {
    .mockup-devices {
        width: 46%;
        left: 2%;
        top: 30%;
    }
}

@media only screen and (max-width: 700px) {
    .mockup-devices {
        display: none;
    }
}
