.nseamai-cards {
    width: 100%;
    padding: 0;
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
}

.nseamai-card {
    width: 98%;
    margin: 1em 1%;
    border: #ccc 1px solid;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}

.nseamai-card .side {
    width: 20%;
    margin: 0;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.nseamai-card .side h5 {
    font-size: 1.7em;
}

.nseamai-card .content {
    width: 80%;
    margin: 0;
    padding: 0.5em 1em;
}

.nseamai-card .content p {
    font-size: 0.9em;
    text-align: left;
}

.nseamai-card .content span {
    font-size: 0.9em;
    font-weight: 400;
}

.nseamai-card .content button {
    border: none;
    width: 100%;
    color: #fff;
    margin: 1em 0 0 0;
}


/* Media Screen */
@media only screen and (max-width: 1920px) {
    .nseamai-card {
        width: 31%;
        margin: 1em 1%;
    }
}

@media only screen and (max-width: 1366px) {
    .nseamai-card {
        width: 48%;
        margin: 1em 1%;
    }
}

@media only screen and (max-width: 1000px) {
    .nseamai-card {
        width: 98%;
        margin: 1em 1%;
    }
}


