#ewisemaps-home {
    position: absolute;
    width: 75%;
    height: 100vh;
}

#ewisemaps-home canvas {
    height: 100vh !important;
    margin: 0;
    width: 100%;
    background-image: linear-gradient( 160deg, #FD830C, #fd470c ); 
    background-image: -webkit-linear-gradient(160deg, #FD830C, #fd470c);
    background-image: -moz-linear-gradient(160deg, #FD830C, #fd470c);
    background-image: -o-linear-gradient(160deg, #FD830C, #fd470c);
    padding: 0;
}


/* Media Screen */
@media only screen and (max-width: 1920px) {
    #ewisemaps-home {
        width: 70%;
    }
}

@media only screen and (max-width: 1600px) {
    #ewisemaps-home {
        width: 65%;
    }
}

@media only screen and (max-width: 1024px) {
    #ewisemaps-home {
        width: 55%;
    }
}

@media only screen and (max-width: 700px) {
    #ewisemaps-home canvas {
        display: none;
    }
}
