@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import '~bootstrap/dist/css/bootstrap.css';
@import '~leaflet/dist/leaflet.css';
@import '~leaflet-contextmenu/dist/leaflet.contextmenu.css';
@import '~animate.css/animate.css';
@import '~react-mapbox-autocomplete/index.css';

html {
  scroll-behavior: smooth;
  margin: 0;
  padding: 0;
}


body{
  min-width: 100%;
  max-width: 100%;
  min-height: 100vh;
  font-family: 'Lato', cursive !important;
  background-color:#f5f5f5 !important;
  color: #262626;
  margin: 0;
  padding: 0;
}

#root {
  min-width: 100%;
  max-width: 100%;
  margin: 0;
  padding: 0;
}

a {
  cursor: pointer;
  text-decoration: none;
}

a:hover {
  color:#2C364C !important;
}

*:focus {
  outline: none;
}

*:active {
  outline: none;
  border: none;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

select {
  border: none;
  border-bottom: #ccc 2px solid;
  padding: 0.5em 1em;
  cursor: pointer;
  line-height: inherit;
  outline: none;
  font-weight: 300;
}

select::-ms-expand {
  display: none;
}


::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1; 
}

::-webkit-scrollbar-thumb {
  background: #888; 
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #444; 
}

button:active {
  outline: none;
  border: none;
}

button:focus {outline:0;}

.ewisemaps-lg-modal {
  padding-left: 0 !important;
}

.ewisemaps-lg-modal .modal-content{
  border-radius: 0;
}

.ewisemaps-lg-modal .modal-header{
  border-bottom: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.ewisemaps-lg-modal .modal-title {
  font-weight: 300;
}

.ewisemaps-lg-modal .viewer-toolbar-left {
  display: none;
}

.ewisemaps-lg-modal .viewer-open-file {
  display: none;
}

@media only screen and (max-width: 1280px) {
  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
}