#chart-custom-date {
    height: auto;
    background-color: #fff;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    padding: 2em;
    text-align: center;
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}

.chart-custom-date-size-lg{
    width: 98%;
    margin: 1em 1%;
}

.chart-custom-date-size-md{
    width: 48%;
    margin: 1em 1%;
}

#chart-custom-date .actions {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

#chart-custom-date .actions .interval {
    width: 31%;
    margin: 1em 1%;
    text-align: left;
}

#chart-custom-date .actions .print {
    width: 31%;
    margin: 1em 1%;
    text-align: center;
}

#chart-custom-date .actions .type {
    width: 31%;
    margin: 1em 1%;
    text-align: right;
}

#chart-custom-date .actions label {
    margin: 0 10px;
    cursor: pointer;
    font-size: 1.2em;
    color: #2C364C;
}

#chart-custom-date .actions label .active {
    color: #FD830C;
}

#chart-custom-date .actions input {
    display: none;
}

.view-info {
    width: auto;
    color: #2C364C;
    cursor: pointer;
}

.view-info.active {
    color: #FD830C;
}

/* Media Screen */
@media only screen and (max-width: 1920px) {
    #chart-custom-date h3{
        font-size: 1.5em;
    }
}

@media only screen and (max-width: 1600px) {
    #chart-custom-date h3{
        font-size: 1.4em;
    }
}

@media only screen and (max-width: 1366px) {
    #chart-custom-date h3{
        font-size: 1.3em;
    }
}

@media only screen and (max-width: 1280px) {
    #chart-custom-date h3{
        font-size: 1.2em;
    }
}

@media only screen and (max-width: 1024px) {
    #chart-custom-date h3{
        font-size: 1.1em;
    }
}

@media only screen and (max-width: 800px) {
    #chart-custom-date h3{
        font-size: 1.1em;
    }
    #chart-custom-date .actions .interval {
        width: 98%;
        text-align: left;
    }
    #chart-custom-date .actions .print {
        width: 98%;
        text-align: left;
    }
    #chart-custom-date .actions .type {
        width: 98%;
    }
}

@media only screen and (max-width: 600px) {
    #chart-custom-date h3{
        font-size: 1em;
    }
    #chart-custom-date .actions {
        flex-wrap: wrap;
        flex-direction: row;
    }
}
