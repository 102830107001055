.geotarget-cards {
    width: 100%;
    padding: 0;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
}

.geotarget-card {
    width: 98%;
    margin: 1em 1%;
    border: #ccc 1px solid;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}

.geotarget-card .side {
    width: 5%;
    margin: 0;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.geotarget-card .side h5 {
    font-size: 1em;
}

.geotarget-card .content {
    width: 90%;
    margin: 0;
    padding: 0.5em 1em;
}

.geotarget-card .content p {
    font-size: 1em;
    text-align: left;
    display:inline;
}

.geotarget-card .content span {
    font-size: 1em;
    font-weight: bolder;
    display:inline;
}

.geotarget-card .content button {
    border: none;
    width: 100%;
    color: #fff;
    margin: 1em 0 0 0;
}



/* Media Screen */
@media only screen and (max-width: 1920px) {
    .geotarget-card {
        width: 23%;
        margin: 1em 1%;
    }
}

@media only screen and (max-width: 1024px) {
    .geotarget-card {
        width: 31%;
        margin: 1em 1%;
    }
}

@media only screen and (max-width: 1000px) {
    .geotarget-card {
        width: 48%;
        margin: 1em 1%;
    }
}

@media only screen and (max-width: 700px) {
    .geotarget-card {
        width: 98%;
        margin: 1em 1%;
    }
}

