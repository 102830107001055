.filter-custom-date {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row;
}

.filter-custom-date h5 {
    width: 100%;
}

.filter-custom-date .range, .select {
    text-align: center;
    width: 48%;
    margin: 2em 1%;
}

.filter-custom-date .range h6, .select h6 {
    font-weight: bold;
    padding: 1em 0;
}

.filter-custom-date .range .react-calendar__tile--active {
    background: #2C364C;
}

.filter-custom-date .range .react-calendar__tile--now {
    background: #FD830C;
    color: #fff;
}

.filter-custom-date .range .react-daterange-picker__clear-button {
    display: none;
}

.react-radio-group[data-readonly="true"] {
    pointer-events: none;
    user-select: none;
}

.react-radio-group label {
    width: 100%;
    text-align: left;
    cursor: pointer;
}

.react-radio-group label input{
    margin-right: 1em;
}