#app-web-loading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 9999;
}

#app-web-loading  .progress-bar {
    height: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    overflow: hidden;
}
  
#app-web-loading  .progress-bar-value {
    width: 100%;
    height: 100%;
    background-color: #FD830C;
    animation: indeterminateAnimation 1s infinite linear;
    transform-origin: 0% 50%;
}
  
@keyframes indeterminateAnimation {
    0% {
      transform:  translateX(0) scaleX(0);
    }
    40% {
      transform:  translateX(0) scaleX(0.4);
    }
    100% {
      transform:  translateX(100%) scaleX(0.5);
    }
}