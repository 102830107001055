.export-csv .csvs {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.export-csv .csvs button {
    width: 98%;
    margin: 0.5em 1%;
    border: none;
    background-color: transparent;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    border-bottom: 1px #ccc solid;
}

.export-csv .csvs button span {
    width: 90%;
    text-align: left;
    font-size: 0.8em;
}

.export-csv .csvs button span small {
    font-size: 1.1em;
    color: #777;
    margin-right: 1em;
}

.export-csv .csvs button i {
    width: 10%;
    text-align: center;
    color: teal;
    font-size: 1.3em;
}