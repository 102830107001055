.modal-map-panel-mobile .modal-content{
    border-radius: 0;
}

.modal-map-panel-mobile .modal-header{
    border-bottom: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.modal-map-panel-mobile .modal-title {
    font-weight: 300;
}

.modal-map-panel-mobile iframe {
    width: 100%;
    border: none;
    height: 70vh;
}