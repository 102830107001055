.list-basemaps {
    width: 100%;
    padding: 2em 1em;
    display: flex;
    align-items: center;
    justify-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    list-style: none;
    height: 70vh;
    overflow-y: auto;
}

.list-basemaps li {
    width: 95%;
    margin: 0.5em 2.5%;
    padding: 0;
}

.list-basemaps li button {
    border: none;
    background-color: transparent;
    padding: 0;
    height: 120px;
    width: 100%;
    display: flex;
    align-items: flex-end;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    font-weight: 300;
}

.list-basemaps li button span {
    padding: 0.5em;
    width: 100%;
    background-color: #fff;
}


/* Media Screen */
@media only screen and (max-width: 1920px) {
    .list-basemaps {
        padding: 2em 1em;
        height: 72vh;
    }
    .list-basemaps li {
        width: 95%;
        margin: 0.5em 2.5%;
    }
    .list-basemaps li button {
        height: 120px;
    }
    .list-basemaps li button span {
        padding: 0.5em;
    }
}

@media only screen and (max-width: 1600px) {
    .list-basemaps {
        padding: 2em 1em;
        height: 70vh;
    }
    .list-basemaps li {
        width: 96%;
        margin: 0.5em 2%;
    }
    .list-basemaps li button {
        height: 100px;
    }
    .list-basemaps li button span {
        padding: 0.5em;
    }
}

@media only screen and (max-width: 1366px) {
    .list-basemaps {
        padding: 2em 1em;
        height: 67vh;
    }
    .list-basemaps li button span {
        font-size: 0.9em;
    }
}

@media only screen and (max-width: 1280px) {
    .list-basemaps {
        padding: 2em 1em;
        height: 66vh;
    }
    .list-basemaps li button span {
        font-size: 0.9em;
    }
}

@media only screen and (max-width: 1024px) {
    .list-basemaps {
        padding: 2em 1em;
        height: 69vh;
    }
}
