#ewisemaps-filter-location-options {
    font-weight: 300;
}

#ewisemaps-filter-location-options h5 {
    font-size: 1.3em;
}

#ewisemaps-filter-location-options nav {
    font-size: 1em;
}

#ewisemaps-filter-location-options nav a{
    width: 33%;
    color: #262626;
    border-radius: 0;
}

#ewisemaps-filter-location-options nav a:hover{
    color: #fff !important;
    background-color: #2C364C;
}

#ewisemaps-filter-location-options nav a.active{
    color: #fff;
    background-color: #2C364C;
}


/* Media Screen */
@media only screen and (max-width: 1920px) {
    #ewisemaps-filter-location-options h5 {
        font-size: 1.1em;
    }
    #ewisemaps-filter-location-options h6 {
        font-size: 0.9em;
    }
    #ewisemaps-filter-location-options nav {
        font-size: 0.9em;
    }
}

@media only screen and (max-width: 1000px) {
    #ewisemaps-filter-location-options h5 {
        font-size: 1em;
    }
    #ewisemaps-filter-location-options h6 {
        font-size: 0.8em;
    }
    #ewisemaps-filter-location-options nav {
        font-size: 0.8em;
    }
}


