.leaflet-top {
	top: 0;
	z-index: auto;
}
.leaflet-right {
	right: 0;
	z-index: auto;
}
.leaflet-bottom {
	bottom: 0;
	z-index: auto;
}
.leaflet-left {
	left: 0;
	z-index: auto;
}

.leaflet-container {
	cursor: pointer;
}

.leaflet-contextmenu {
	cursor: pointer;
	width: 200px !important;
}

.leaflet-contextmenu a.leaflet-contextmenu-item {
    color: #262626;
    font-size: 1.1em;
	text-align: left;
	font-family: 'Lato';
	cursor: pointer;
	padding: 0.2em 1em;
}

.leaflet-tooltip {
	width: 80px;
	text-align: left;
	z-index: 1000;
}

.leaflet-tooltip span{
	white-space: pre-line;
	font-size: 0.9em;
}

.toast-message-ewisemaps {
	position: absolute;
	top: 10px;
	right: 10px;
	z-index: 1000;
	background-color: #fff;
}

/* Default icon URLs */
.leaflet-default-icon-path {
	background-image: url(../../../assets/pin_map.png) !important;
}


@media only screen and (max-width: 1024px) {
    .leaflet-control-zoom {
		display: none !important;
	}
	#box-zoom-button {
		display: none !important;
	}
}


