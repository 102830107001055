#app-filter-section {
    width: 100%;
    background-color: none;
    min-height: 500px;
    max-height: 500px;
    overflow-y: auto;
}

#app-filter-section ul li a {
    padding: 0;
    margin: 0;
    width: 100%;
    display: flex;
}

#app-filter-section ul li span {
    padding: 0 0 0 1em;
    margin: 0;
    width: 80%;
    text-align: left;
    font-size: 1.1em;
}

#app-filter-section ul li .switch_box{
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	width: 20%;
	height: 50px;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	-webkit-box-flex: 1;
	    -ms-flex: 1;
	        flex: 1;
}

#app-filter-section ul li .box_1{
	background: #fff;
}

#app-filter-section ul li input[type="checkbox"].switch_1{
	font-size: 10px;
	-webkit-appearance: none;
	   -moz-appearance: none;
	        appearance: none;
	width: 2.5em;
	height: 1.5em;
	background: #ddd;
	border-radius: 20px;
	position: relative;
	cursor: pointer;
	outline: none;
	-webkit-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out;
}

#app-filter-section ul li input[type="checkbox"].switch_1:checked{
    background: #FD830C;
}

#app-filter-section ul li input[type="checkbox"].switch_1:after{
    position: absolute;
    content: "";
    width: 1.5em;
    height: 1.5em;
    border-radius: 50%;
    background: #fff;
    -webkit-box-shadow: 0 0 .25em rgba(0,0,0,.3);
            box-shadow: 0 0 .25em rgba(0,0,0,.3);
    -webkit-transform: scale(.7);
            transform: scale(.7);
    left: 0;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}

#app-filter-section ul li input[type="checkbox"].switch_1:checked:after{
    left: calc(100% - 1.5em);
}

.filter-modal .modal-content .menu-section-options .nav-link.active{
    background-color: transparent;
    border-radius: 0;
    color: #555;
    border-left: #FD830C 3px solid;
}

.filter-modal .modal-content .menu-section-options .nav-link{
    background-color: transparent;
    color: #555;
    border-radius: 0;
}

.filter-modal .actions {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
}

.filter-modal .actions input {
    width: 96%;
    margin: 0 2%;
    border: none;
    border-bottom: 1px solid #ccc;
}

.filter-modal .actions button {
    width: 21%;
    margin: 2em 2%;
    background-color: #FD830C;
    border: none;
    color: #fff;
    padding: 0.2em;
}

.filter-modal .modal-content .menu-section-options .dropdown button{
    background-color: transparent;
    border: none;
    color: #555;
    float: left;
    padding: 1em;
}

.filter-modal .modal-content .filter-location {
    width: 100%;
    padding: 0;
}

.filter-modal .modal-content .filter-location .selects-list {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
}

.filter-modal .modal-content .filter-location .selects-list select {
    width: 31%;
    margin: 1em 1%;
}

.filter-modal .modal-content .chips-location ul {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    list-style: none;
    overflow-y: auto;
    padding: 0;
}

.filter-modal .modal-content .chips-location ul li {
    display: block;
    width: 44%;
    margin: 1em 3%;
    padding: 0.5em;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}

.filter-modal .modal-content .chips-location ul li .action{
    width: 100%;
    padding: 0.5em;
}

.filter-modal .modal-content .chips-location ul li .action button{
    float: right;
    background-color: transparent;
    border: none;
    color: #aaa;
}

.filter-modal .modal-content .chips-location ul li .name {
    width: 100%;
    padding: 0.5em;
}

.filter-section-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.menu-section-options {
    width: 14%;
    margin: 1em 1%;
}

.filter-section-options {
    width: 82%;
    margin: 1em 1%;
}

/* Media Screen */
@media only screen and (max-width: 1920px) {
    #app-filter-section {
        min-height: 500px;
        max-height: 500px;
    }
    #app-filter-section ul li span {
        width: 80%;
        font-size: 1em;
    }
    #app-filter-section ul li .switch_box{
        width: 20%;
        height: 20px;
    }
    #app-filter-section ul li input[type="checkbox"].switch_1{
        font-size: 8px;
        width: 2.5em;
        height: 1.5em;
        border-radius: 20px;
    }
    #app-filter-section ul li input[type="checkbox"].switch_1:after{
        width: 1.5em;
        height: 1.5em;
        border-radius: 50%;
    }
    .filter-modal .modal-content .menu-section-options .dropdown{
        display: none;
    }
    .filter-modal .modal-dialog {
        max-width: 80%;
    }
    .filter-modal .modal-content .chips-location ul {
        height: 320px;
    }
    .menu-section-options {
        width: 14%;
    }
    .filter-section-options {
        width: 82%;
    }
}

@media only screen and (max-width: 1600px) {
    #app-filter-section {
        min-height: 480px;
        max-height: 480px;
    }
    .menu-section-options {
        width: 18%;
    }
    .filter-section-options {
        width: 78%;
    }
}

@media only screen and (max-width: 1366px) {
    #app-filter-section {
        min-height: 430px;
        max-height: 430px;
    }
    #app-filter-section ul li span {
        font-size: 0.95em;
    }
    .filter-modal .modal-content .chips-location ul {
        height: 270px;
    }
}

@media only screen and (max-width: 1280px) {
    .menu-section-options {
        width: 23%;
    }
    .filter-section-options {
        width: 73%;
    }
}

@media only screen and (max-width: 1024px) {
    .filter-modal .modal-content .menu-section-options .flex-column{
        display: none;
    }
    .filter-modal .modal-content .menu-section-options .dropdown{
        display: block;
    }
    .filter-modal .modal-dialog {
        max-width: 95%;
    }
    .menu-section-options {
        width: 98%;
    }
    .filter-section-options {
        width: 98%;
    }
}

@media only screen and (max-width: 1000px) {
    #app-filter-section {
        min-height: 420px;
        max-height: 420px;
    }
    .filter-modal .modal-content .chips-location ul {
        height: 260px;
    }
}

@media only screen and (max-width: 700px) {
    #app-filter-section {
        min-height: 380px;
        max-height: 380px;
    }
    .filter-modal .actions button {
        width: 48%;
        margin: 1em 1%;
        background-color: #FD830C;
        border: none;
        color: #fff;
        padding: 0.2em;
    }
    .filter-modal .modal-content .chips-location  ul{
        width: 100%;
    }
    .filter-modal .modal-content .chips-location ul li {
        width: 98%;
        margin: 1em 1%;
        font-size: 0.9em;
    }
    .filter-modal .modal-content .chips-location ul {
        height: 200px;
    }
}



