#ewisemaps-forgot-password {
    position: fixed;
    background-color: #fff;
    top: 0;
    right: 0;
    width: 27%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}

#ewisemaps-forgot-password .form {
    padding: 2em;
    width: 90%;
    margin: 0 5%;
}

#ewisemaps-forgot-password .form h1 {
    text-align: center;
    padding: 2em 0;
}

#ewisemaps-forgot-password .form .form-item {
    padding: 1.5em 0;
    text-align: center;
}

#ewisemaps-forgot-password .form .legend {
    color: #888;
    padding: 1.5em 0;
    text-align: center;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}

#ewisemaps-forgot-password .form .legend span {
    font-weight: 300;
    font-size: 0.9em;
}

#ewisemaps-forgot-password .form .legend span a {
    color: #888;
}

#ewisemaps-forgot-password .form .legend img {
    width: 30px;
    height: 30px;
}

#ewisemaps-forgot-password .form .options {
    padding: 2em 0;
    text-align: center;
}

#ewisemaps-forgot-password .form .options a {
    color: #FD830C;
}

#ewisemaps-forgot-password .form input[type="text"], [type="email"], [type="password"] {
  width: 100%;
  padding: 0.5em 1em;
  font-size: 0.9em;
  border: none;
  border-bottom: #ccc 2px solid;
}

#ewisemaps-forgot-password .form input[type="text"]:hover, [type="email"]:hover, [type="password"]:hover{
  border-bottom: #FD830C 2px solid;
}

#ewisemaps-forgot-password .form input[type="text"]:active, [type="email"]:active, [type="password"]:active{
  border-bottom: #FD830C 2px solid;
}

#ewisemaps-forgot-password .form input[type="text"]:focus, [type="email"]:focus, [type="password"]:focus{
  border-bottom: #FD830C 2px solid;
}

#ewisemaps-forgot-password .form button {
  background-color: #FD830C;
  padding: 0.5em 1.5em;
  color: #fff;
  font-weight: 400;
  font-size: 0.9em;
  border: none;
  cursor: pointer;
  text-transform: uppercase;
}

#ewisemaps-forgot-password .form button:hover {
  background-color: #e67709;
}


/* Media Screen */
@media only screen and (max-width: 1920px) {
  #ewisemaps-forgot-password {
    width: 30%;
  }
  #ewisemaps-forgot-password .form {
    padding: 2em;
    width: 80%;
    margin: 0 10%;
  }
  #ewisemaps-forgot-password .form h1 {
    padding: 2em 0;
    font-size: 1.8em;
    font-weight: 300;
  }
  #ewisemaps-forgot-password .form .options a {
    font-size: 1em;
  }
  #ewisemaps-forgot-password .form input[type="text"], [type="email"], [type="password"] {
    font-size: 0.9em;
  }
  #ewisemaps-forgot-password .form button {
    font-size: 0.9em;
  }
  #ewisemaps-forgot-password .form .legend span a {
    font-size: 1em;
  }
  #ewisemaps-forgot-password .form .legend img {
      width: 30px;
      height: 30px;
  }
}

@media only screen and (max-width: 1600px) {
  #ewisemaps-forgot-password {
    width: 35%;
  }
}

@media only screen and (max-width: 1024px) {
  #ewisemaps-forgot-password {
    width: 45%;
  }
}

@media only screen and (max-width: 800px) {
  #ewisemaps-forgot-password .form {
    padding: 1em;
    width: 90%;
    margin: 0 5%;
  }
  
  #ewisemaps-forgot-password .form .legend span{
    padding: 1em;
  }
}

@media only screen and (max-width: 700px) {
  #ewisemaps-forgot-password {
    width: 100%;
  }
  #ewisemaps-forgot-password .form .legend {
    position:relative;
  }
  #ewisemaps-forgot-password .form .legend span{
    padding: 2em 1em;
    font-size: 0.8em;
  }
}
