#ewisemaps-map-panel {
    font-weight: 300;
}

#ewisemaps-map-panel h4 {
    font-size: 1.3em;
    font-weight: 300;
}

#ewisemaps-map-panel nav {
    font-size: 1em;
}

#ewisemaps-map-panel nav a{
    width: 33%;
    color: #262626;
    border-radius: 0;
}

#ewisemaps-map-panel nav a:hover{
    color: #fff !important;
    background-color: #2C364C;
}

#ewisemaps-map-panel nav a.active{
    color: #fff;
    background-color: #2C364C;
}


/* Media Screen */
@media only screen and (max-width: 1920px) {
    #ewisemaps-map-panel h4 {
        font-size: 1.3em;
    }
    #ewisemaps-map-panel nav {
        font-size: 1.1em;
    }
}

@media only screen and (max-width: 1600px) {
    #ewisemaps-map-panel h4 {
        font-size: 1.2em;
    }
    #ewisemaps-map-panel nav {
        font-size: 1em;
    }
}

@media only screen and (max-width: 1366px) {
    #ewisemaps-map-panel nav {
        font-size: 0.8em;
    }
}

@media only screen and (max-width: 1280px) {
    #ewisemaps-map-panel nav {
        font-size: 0.7em;
    }
}

@media only screen and (max-width: 1024px) {
    #ewisemaps-map-panel h4 {
        font-size: 1.1em;
    }
    #ewisemaps-map-panel nav {
        font-size: 0.6em;
    }
}


